import axios from "axios";
import type { Role } from "../models/role";
import type { User } from "../models/user";
import { uris } from "../config/vars";

export const addClientUserRole = (user: User, role: Role) => {
  const uri = uris.ADD_CLIENT_USER_ROLE_URI;
  const data = {
    userId: user.id,
    clientId: role.clientId,
    roleId: role.id,
    roleName: role.name,
  };
  return axios.post(uri, data);
};

export const deleteClientUserRole = (user: User, role: Role) => {
  const uri = uris.DELETE_CLIENT_USER_ROLE_URI;
  const data = {
    userId: user.id,
    clientId: role.clientId,
    roleId: role.id,
    roleName: role.name,
  };
  return axios.post(uri, data);
};
