import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";

import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/ducks";
import { User } from "../../models/user";
import { DefaultProps } from "../../models/react";

const { Header: AntHeader, Content, Sider } = Layout;

const Main: FC<DefaultProps> = function ({ children }) {
  const user: User = useSelector((state: RootState) => state.user);

  const [fixed, setFixed] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [sidenavColor, setSidenavColor] = useState<string>("#488cc5");
  const [sidenavType, setSidenavType] = useState<string>("transparent");

  const closeDrawer = () => setVisible(false);
  const openDrawer = () => setVisible((visible) => !visible);
  const handleFixedNavbar = (type: boolean) => setFixed(type);
  const handleSidenavType = (type: string) => setSidenavType(type);
  const handleSidenavColor = (color: string) => setSidenavColor(color);

  const pageTitle = useSelector((state: RootState) => state.page.title);

  let { pathname } = useLocation();
  pathname = pageTitle || pathname.replace("/", "").replaceAll("-", " ");

  return (
    <Layout className="layout-dashboard">
      <Drawer
        width={250}
        key={"left"}
        title={false}
        open={visible}
        closable={false}
        placement={"left"}
        onClose={closeDrawer}
        className={`drawer-sidebar`}
      >
        <Layout className={`layout-dashboard`}>
          <Sider
            width={250}
            theme="light"
            trigger={null}
            onClick={closeDrawer}
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                user={user}
                name={pathname}
                subName={pathname}
                onPress={openDrawer}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                handleSidenavColor={handleSidenavColor}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              user={user}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Main;
