import { Button, Descriptions } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../models/user";
import { setTitle } from "../../redux/store/ducks/page";
import EditUserDrawer from "./EditUserDrawer";

import ProCard from "@ant-design/pro-card";

const MyAccount: FC<{ user: User }> = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Mis datos"));
  }, [dispatch]);

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 600);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 600)
  );

  const [editUserDrawerVisible, setEditUserDrawerVisible] = useState(false);
  const showDrawer = () => {
    setEditUserDrawerVisible(true);
  };
  const hideDrawer = () => {
    setEditUserDrawerVisible(false);
  };

  return (
    <ProCard
      className="header-solid card-profile-information"
      bordered={!mobileSize}
      title={
        <h6 className="font-semibold m-0" style={{ marginBottom: "15px" }}>
          Mis datos
        </h6>
      }
      style={{ paddingBottom: "0px" }}
      extra={
        <Button
          type="primary"
          onClick={() => showDrawer()}
          style={{ marginBottom: "20px" }}
        >
          Editar
        </Button>
      }
    >
      <Descriptions
        bordered={!mobileSize}
        labelStyle={{ fontSize: "14px", width: mobileSize ? "auto" : "250px" }}
        contentStyle={{ fontSize: "14px" }}
      >
        <Descriptions.Item label="Nombre" span={3}>
          {user.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Apellido" span={3}>
          {user.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre de usuario" span={3}>
          {user.username}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={3}>
          {user.email}
        </Descriptions.Item>
      </Descriptions>

      <EditUserDrawer
        user={user}
        visible={Boolean(editUserDrawerVisible)}
        hideDrawer={hideDrawer}
      />
    </ProCard>
  );
};

export default MyAccount;
