export const PAGE_TYPES = {
  SET_PAGE_TITLE: "SET_PAGE_TITLE",
};

const types = PAGE_TYPES;

export interface PageState {
  title: string;
}

export const setTitle = (title: string) => ({
  type: types.SET_PAGE_TITLE,
  title,
});

const initialState: PageState = {
  title: "Usuarios",
};

const reducer = (state = initialState, action: Record<string, any> = {}) => {
  switch (action.type) {
    case types.SET_PAGE_TITLE:
      document.title = `Usuarios | ${action.title}`;
      return { ...state, title: action.title };

    default:
      return state;
  }
};

export default reducer;
