import { FC, useState } from "react";

import { User } from "../../../models/user";

import { DrawerForm, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { updateUserAttributes } from "../../../services/users";
import { notification } from "antd";
import Title from "antd/es/typography/Title";

interface UserRolesDrawerProps {
  selectedUser: User;
  visible: boolean;
  hideDrawer: () => void;
  onRefresh: () => void;
}

export const UserEditDrawer: FC<UserRolesDrawerProps> = ({
  selectedUser,
  visible,
  hideDrawer,
  onRefresh,
}) => {
  const [mobileSize, setMobileSize] = useState(window.innerWidth < 500);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 500)
  );

  return (
    <DrawerForm<{ belongsTo: string }>
      open={visible}
      initialValues={selectedUser}
      className="settings-drawer"
      width={!mobileSize ? 450 : "100%"}
      drawerProps={{ mask: true, onClose: hideDrawer, destroyOnClose: true }}
      onFinish={async (values) => {
        return updateUserAttributes(selectedUser.id, {
          attributes: values,
        })
          .then(() => {
            notification.success({
              message: `Se ha editado al usuario "${selectedUser.username}" correctamente.`,
            });
            if (onRefresh) onRefresh();
            hideDrawer();
            return true;
          })
          .catch(() => {
            notification.error({
              message: "Ocurrió un error al editar al usuario.",
            });
            return false;
          });
      }}
      title="Editar usuario"
      style={{ margin: 0 }}
    >
      <Title level={4}>
        {Object.keys(selectedUser).length ? (
          selectedUser.label
        ) : (
          <span>&nbsp;</span>
        )}
      </Title>
      <ProFormText
        disabled
        label="Usuario"
        fieldProps={{ value: selectedUser.username }}
      />
      <ProFormText
        disabled
        label="Nombre"
        fieldProps={{ value: selectedUser.firstName }}
      />
      <ProFormText
        disabled
        label="Apellido"
        fieldProps={{ value: selectedUser.lastName }}
      />
      <ProFormText
        disabled
        label="Email"
        fieldProps={{ value: selectedUser.email }}
      />

      <ProFormSelect
        showSearch
        name="belongsTo"
        allowClear={false}
        label="Pertenece a"
        placeholder="Seleccione una opción..."
        options={[
          { label: "Gral", value: "gral" },
          { label: "CyE", value: "cye" },
        ]}
      />
    </DrawerForm>
  );
};

export default UserEditDrawer;
