import axios from "axios";

class KeycloakAuth {
  static getUser = (kc) => {
    const client = kc.tokenParsed.resource_access["user-management"];
    const roles = client ? client.roles : [];
    return {
      id: kc.tokenParsed.sub,
      username: kc.tokenParsed.preferred_username,
      email: kc.tokenParsed.email,
      firstName: kc.tokenParsed.given_name,
      lastName: kc.tokenParsed.family_name,
      enabled: true,
      enabledClients: Object.keys(kc.tokenParsed.resource_access),
      roles,
    };
  };
  static updateSavedToken = (kc) => {
    localStorage.setItem("usuarios-token", kc.token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${kc.token}`;
    localStorage.setItem("usuarios-refreshToken", kc.refreshToken);
  };
  static clearSavedToken = () => {
    localStorage.removeItem("usuarios-token");
    localStorage.removeItem("usuarios-refreshToken");
  };
}

export default KeycloakAuth;
