import {
  Button,
  Col,
  notification,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../../models/user";
import { setTitle } from "../../../redux/store/ducks/page";
import { getUserList } from "../../../services/users";
import UserRolesDrawer from "../UserRolesDrawer";
import { Application } from "../../../models/application";
import { getAllApplications } from "../../../services/applications";
import ProTable, {
  ProColumns,
  ColumnsState,
  ActionType,
} from "@ant-design/pro-table";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/ducks";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  ProfileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import UserGroupsDrawer from "../UserGroupsDrawer";
import { Group } from "../../../models/group";
import { getAllGroups } from "../../../services/groups";
import UserEditDrawer from "../UserEditDrawer";

const isValidApp = (app: Application) => {
  return app.clientName && app.description && app.baseUrl !== "";
};

const UserList: FC = memo(() => {
  const actionRef = useRef<ActionType>();
  const user: User = useSelector((state: RootState) => state.user);

  const [loading, setLoading] = useState<boolean>();
  const [groups, setGroups] = useState<Group[]>([]);
  const [apps, setApps] = useState<Application[]>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle("Gestión de usuarios"));
    if (user.roles.some((r) => ["listGroups", "listRoles"].includes(r)))
      setLoading(true);
    if (user.roles.includes("listRoles"))
      getAllApplications()
        .then((apps) => {
          apps = apps.filter((app: Application) => isValidApp(app));
          setApps(apps);
        })
        .catch(() => {
          setApps([]);
          notification.error({
            message: "Error",
            description:
              "Se ha producido un error al obtener las aplicaciones.",
          });
        })
        .finally(() => setLoading(false));
    if (user.roles.includes("listGroups"))
      getAllGroups()
        .then((groups) => {
          groups = groups.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          setGroups(groups);
        })
        .catch(() => {
          setGroups([]);
          notification.error({
            message: "Error",
            description: "Se ha producido un error al obtener los grupos.",
          });
        })
        .finally(() => setLoading(false));
  }, [dispatch, user]);

  const [columnsState, setColumnsState] = useState<
    Record<string, ColumnsState>
  >(
    localStorage.getItem("usersListSettings")
      ? // @ts-ignore
        JSON.parse(localStorage.getItem("usersListSettings"))
      : {
          id: {
            show: false,
          },
        }
  );

  const columns: ProColumns<User>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      search: false,
    },
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
      sorter: true,
      fieldProps: { placeholder: "Ingrese un usuario..." },
    },
    {
      title: "Apellido",
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
      fieldProps: { placeholder: "Ingrese un apellido..." },
    },
    {
      title: "Nombre",
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
      fieldProps: { placeholder: "Ingrese un nombre..." },
    },
    {
      title: "Email",
      dataIndex: "email",
      copyable: true,
      key: "email",
      fieldProps: { placeholder: "Ingrese un email..." },
    },
    {
      title: "Pertenece a",
      dataIndex: "belongsTo",
      key: "belongsTo",
      fieldProps: { placeholder: "Seleccione una opción..." },
      valueEnum: {
        gral: { text: "Gral" },
        cye: { text: "CyE" },
      },
      align: "left",
      render(dom, entity, index, action, schema) {
        const color = entity.belongsTo === "gral" ? "#488cc5" : "#a3d27c";
        return (
          <Tag color={color} key={entity.id}>
            {entity.belongsTo?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "enabled",
      key: "enabled",
      valueType: "select",
      valueEnum: {
        true: { text: "Activo" },
        false: { text: "Inactivo" },
      },
      fieldProps: { placeholder: "Seleccione un estado..." },
      align: "left",
      render: (_, user) => (
        <Space
          style={{
            verticalAlign: "middle",
          }}
        >
          {user.enabled ? (
            <Tooltip title="Usuario activo">
              <CheckCircleFilled
                style={{ fontSize: "20px", color: "#a3d27c" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Usuario inactivo">
              <CloseCircleFilled
                style={{ fontSize: "20px", color: "#a4a4a4" }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const [selectedUser, setSelectedUser] = useState<User>({} as User);

  const [rolesDrawerVisible, setRolesDrawerVisible] = useState<boolean>(false);
  const showRolesDrawer = (user: User) => {
    setSelectedUser(user);
    setRolesDrawerVisible(true);
  };
  const hideRolesDrawer = () => {
    setRolesDrawerVisible(false);
    setSelectedUser({} as User);
  };

  const [groupsDrawerVisible, setGroupsDrawerVisible] =
    useState<boolean>(false);

  const showGroupsDrawer = (user: User) => {
    setSelectedUser(user);
    setGroupsDrawerVisible(true);
  };

  const hideGroupsDrawer = () => {
    setGroupsDrawerVisible(false);
    setSelectedUser({} as User);
  };

  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);

  const showEditDrawer = (user: User) => {
    setSelectedUser(user);
    setEditDrawerVisible(true);
  };

  const hideEditDrawer = () => {
    setEditDrawerVisible(false);
    setSelectedUser({} as User);
  };

  const onRefresh = () => {
    actionRef.current?.reload();
  };

  if (user.roles.some((r) => ["listGroups", "listRoles"].includes(r))) {
    if (user.roles.includes("listRoles"))
      columns.push({
        title: "Rol",
        key: "role",
        hideInTable: true,
        renderFormItem: () => (
          <TreeSelect
            treeData={apps.map((app) => ({
              title: app.clientName,
              value: app.id,
              key: app.id,
              selectable: false,
              children: app.roleMappings
                .map((role) => ({
                  title: role.description,
                  value: `${app.id} ${role.name}`,
                }))
                .sort((a: any, b: any) => a.title?.localeCompare(b?.title)),
            }))}
            allowClear={true}
            showSearch={false}
            placeholder="Seleccione un rol..."
            dropdownMatchSelectWidth={400}
            listHeight={400}
          />
        ),
      });
    if (user.roles.includes("listGroups"))
      columns.push({
        title: "Grupo",
        key: "group",
        hideInTable: true,
        valueEnum: groups.reduce((acc: any, group) => {
          acc[group.id] = { text: group.name };
          return acc;
        }, {}),
        valueType: "select",
        fieldProps: {
          showSearch: true,
          placeholder: "Seleccione un grupo...",
          listHeight: 400,
        },
      });
    columns.push({
      align: "center",
      title: "Gestionar",
      key: "actions",
      hideInSearch: true,
      render: (_, selectedUser) => (
        <Space>
          {user.roles.includes("listRoles") && (
            <Tooltip title="Roles">
              <Button
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
                style={{ color: "white" }}
                onClick={() => showRolesDrawer(selectedUser)}
              />
            </Tooltip>
          )}
          {user.roles.includes("listGroups") && (
            <Tooltip title="Grupos">
              <Button
                type="primary"
                shape="circle"
                icon={<TeamOutlined />}
                style={{ backgroundColor: "#08477c" }}
                onClick={() => showGroupsDrawer(selectedUser)}
              />
            </Tooltip>
          )}
          {user.roles.includes("editUsers") && (
            <Tooltip title="Editar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => showEditDrawer(selectedUser)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    });
  }

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <div className="tabled">
      <UserGroupsDrawer
        selectedUser={selectedUser}
        allGroups={groups}
        visible={groupsDrawerVisible}
        hideDrawer={hideGroupsDrawer}
      />
      <UserRolesDrawer
        selectedUser={selectedUser}
        allApps={apps}
        visible={rolesDrawerVisible}
        hideDrawer={hideRolesDrawer}
      />
      <UserEditDrawer
        onRefresh={onRefresh}
        selectedUser={selectedUser}
        visible={editDrawerVisible}
        hideDrawer={hideEditDrawer}
      />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <ProTable<User>
            columns={columns}
            defaultSize="small"
            actionRef={actionRef}
            scroll={{ x: true, y: window.outerHeight - 270 }}
            search={{
              layout: "vertical",
              defaultCollapsed: false,
            }}
            locale={{
              triggerDesc: "Click para ordenar descendente",
              triggerAsc: "Click para ordenar ascendente",
              cancelSort: "Click para cancelar ordenamiento",
            }}
            request={async (params, sort) => {
              const { current, pageSize, ...filter } = params;

              return getUserList(sort, filter)
                .then((res) => ({
                  data: res,
                  success: true,
                  total: res.length,
                }))
                .catch(() => {
                  notification.error({
                    message: "Error",
                    description:
                      "Se ha producido un error al obtener los usuarios.",
                  });
                  return { data: [], success: false, total: 0 };
                });
            }}
            rowKey="id"
            pagination={{
              pageSize: 30,
              showSizeChanger: false,
              showTotal(total: number, range: number[]) {
                return `${range[0]}-${range[1]} de ${total} usuarios`;
              },
            }}
            className="ant-border-space"
            columnsState={{
              value: columnsState,
              onChange(map) {
                setColumnsState(map);
                localStorage.setItem("usersListSettings", JSON.stringify(map));
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
});

export default UserList;
