import axios from "axios";
import type { Group } from "../models/group";
import { User } from "../models/user";
import { getUserApplications } from "./applications";
import { deleteClientUserRole } from "./roles";
import { uris } from "../config/vars";

export const getAllGroups = () => {
  const url = uris.GROUPS_URI;
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  return axios.get<Group[]>(url, config).then((e) => e.data);
};

export const getUserGroups = (userId: string) => {
  const url = uris.USER_GROUPS_URI.replace(":userId", userId);
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  return axios.get<Group[]>(url, config).then((e) => e.data);
};

const deleteUserRolesFromGroup = async (user: User, group: Group) => {
  const promises = (await getUserApplications(user.id))
    .map((app) =>
      app.roleMappings.map((r) => {
        if (group.roleMappings.some((gr) => gr.id === r.id)) {
          return new Promise((resolve, reject) =>
            resolve(deleteClientUserRole(user, r))
          );
        } else return null;
      })
    )
    .flat();
  await Promise.all(promises);
};

export const addUserGroup = async (user: User, group: Group) => {
  await deleteUserRolesFromGroup(user, group);

  const uri = uris.ADD_USER_GROUP_URI;
  const data = {
    userId: user.id,
    groupId: group.id,
  };
  return axios.post(uri, data);
};

export const deleteUserGroup = (user: User, group: Group) => {
  const uri = uris.DELETE_USER_GROUP_URI;
  const data = {
    userId: user.id,
    groupId: group.id,
  };
  return axios.post(uri, data);
};
