import axios from "axios";

import type { User } from "../models/user";
import { uris } from "../config/vars";

export const getUserList = (sort: any, filter: any) => {
  const url = uris.USERS_URI;
  if (filter.role)
    filter.role = {
      clientId: filter.role.split(" ")[0],
      roleName: filter.role.split(" ")[1],
    };

  return axios.post<User[]>(url, filter).then((res) => {
    if (Object.keys(sort).length) {
      const field = Object.keys(sort)[0];
      const sortDirection = sort[field] === "ascend" ? 1 : -1;
      res.data.sort((a: any, b: any) =>
        a[field] > b[field] ? sortDirection : -sortDirection
      );
    }
    return res.data.map((user) => ({
      ...user,
      label: `${user.firstName} ${user.lastName} (${user.username})`,
    }));
  });
};

export const updateUserAttributes = (
  userId: string,
  data: {
    attributes: Record<string, any>;
  }
) => {
  return axios
    .patch(`${uris.USERS_URI}/${userId}/attributes`, data)
    .then((e) => e.data);
};

export const updateUser = (data: any) => {
  data = {
    id: data.id,
    username: data.username,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    emailVerified: true,
    attributes: { locale: ["es"] },
    userProfileMetadata: {
      attributes: [
        {
          name: "username",
          displayName: data.username,
          required: true,
          readOnly: false,
          validators: {},
        },
        {
          name: "email",
          displayName: data.email,
          required: true,
          readOnly: false,
          validators: { email: { "ignore.empty.value": true } },
        },
        {
          name: "firstName",
          displayName: data.firstName,
          required: true,
          readOnly: false,
          validators: {},
        },
        {
          name: "lastName",
          displayName: data.lastName,
          required: true,
          readOnly: false,
          validators: {},
        },
      ],
    },
  };
  return axios.post(uris.UPDATE_USER_URI, data).then((e) => e.data);
};
