export const COLORS = {
  "user-management": {
    background: "#1990FF",
    text: "#FFFFFF",
  },
  stock: {
    background: "#030303",
    text: "#FFFFFF",
  },
  "security-admin-console": {
    background: "#030303",
    text: "#FFFFFF",
  },
  cuadrillas: {
    background: "#2196F3",
    text: "#FFFFFF",
  },
  "anexo-stock": {
    background: "#01a2ae",
    text: "#FFFFFF",
  },
  legajos: {
    background: "#292E34",
    text: "#FFFFFF",
  },
  "movimientos-de-depositos": {
    background: "#263238",
    text: "#FFFFFF",
  },
  "human-resources": {
    background: "#263238",
    text: "#FFFFFF",
  },
  ingresos: {
    background: "#009688",
    text: "#FFFFFF",
  },
  "lecturas-frontend": {
    background: "#162C50",
    text: "#FFFFFF",
  },
  "nomenclador-web": {
    background: "#447992",
    text: "#FFFFFF",
  },
  pedidos: {
    background: "#002131",
    text: "#FFFFFF",
  },
  "dashboard-web": {
    background: "#048EBC",
    text: "#FFFFFF",
  },
  "tareas-web": {
    background: "#2196F3",
    text: "#FFFFFF",
  },
  vehiculos: {
    background: "#0277bf",
    text: "#FFFFFF",
  },
};
