import dayjs from "dayjs";
import { createRoot } from "react-dom/client";
import Keycloak from "keycloak-js";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import axios from "axios";
import store from "./redux/store";
import Auth from "./utils/KeycloakAuth";
import { loginUser } from "./redux/store/ducks/user";
import {
  authError,
  authStart,
  authSuccess,
} from "./redux/store/ducks/keycloak";
import VersionAlert from "./components/VersionAlert";

import "dayjs/locale/es";

dayjs.locale("es");

const keycloak = new Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`);

const setAxiosInterceptos = (keycloak) => {
  const keycloakInt = async (config) => {
    try {
      const refreshed = await keycloak.updateToken();
      if (refreshed) {
        Auth.updateSavedToken(keycloak);
      }
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(config);
    } catch (error) {
      keycloak.login();
    }
  };

  axios.interceptors.request.use(keycloakInt);

  const retry = (error) => {
    return Promise.reject(error);
  };

  axios.interceptors.response.use((response) => response, retry);
};

const startApp = async (store) => {
  store.dispatch(authStart());
  try {
    const authenticated = await keycloak.init({
      checkLoginIframe: false,
      onLoad: "login-required",
      token: localStorage.getItem("usuarios-token"),
      refreshToken: localStorage.getItem("usuarios-refreshToken"),
    });
    if (authenticated) {
      try {
        await keycloak.updateToken();
      } catch (error) {
        return keycloak.login();
      }
      const user = Auth.getUser(keycloak);
      Auth.updateSavedToken(keycloak);
      setAxiosInterceptos(keycloak);
      store.dispatch(loginUser(user));
      store.dispatch(authSuccess(keycloak));
    } else {
      return keycloak.login();
    }
  } catch (error) {
    store.dispatch(authError());
  }
};

const publicUrl = document.createElement("a");
publicUrl.href = process.env.PUBLIC_URL;

const app = (
  <Provider store={store}>
    <VersionAlert />
    <BrowserRouter basename={process.env.PUBLIC_URL ? publicUrl.pathname : "/"}>
      <App />
    </BrowserRouter>
  </Provider>
);

// keycloak integration
startApp(store);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(app);
