import { User } from "../../../models/user";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

const types = { LOGIN_USER: "LOGIN_USER" };

export type UserState = User;

export const loginUser = (user: User) => {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch({ type: types.LOGIN_USER, user });
  };
};

const initialState: User = {
  id: "",
  email: "",
  username: "",
  firstName: "",
  lastName: "",
  label: "",
  enabled: true,
  belongsTo: "gral",
  enabledClients: [],
  roles: [],
};

const reducer = (state: UserState = initialState, action: any = {}) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return action.user;
    default:
      return state;
  }
};

export default reducer;
