import dayjs from "dayjs";
import { Avatar, Card, Col, List, Row, Tooltip, Typography } from "antd";
import versions from "./versions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setTitle } from "../../redux/store/ducks/page";
import { Version } from "../../models/version";
import { User } from "../../models/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/ducks";

const { Title } = Typography;

export const VersioningList = () => {
  const user: User = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Actualizaciones"));
  }, [dispatch]);

  const isAffected = (version: Version) =>
    !version.affectedRoles ||
    user.roles.some((r: string) => version.affectedRoles?.includes(r));

  return (
    <>
      <Title level={4} title="Actualizaciones" />
      <Row gutter={[16, 16]}>
        {versions.map((v: Version) =>
          isAffected(v) ? (
            <Col span={24}>
              <Card
                title={
                  <Tooltip
                    title={`${dayjs(v.timestamp * 1000).format(
                      "DD/MM/YYYY HH:mm"
                    )}hs`}
                  >{`Versión ${v.version}`}</Tooltip>
                }
              >
                <List
                  dataSource={v.changes}
                  itemLayout="horizontal"
                  renderItem={(item: {
                    title: string;
                    avatar: any;
                    description: string;
                  }) => (
                    <List.Item key={item.title}>
                      <List.Item.Meta
                        title={item.title}
                        avatar={<Avatar shape="square">{item.avatar}</Avatar>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          ) : null
        )}
      </Row>
    </>
  );
};

export default VersioningList;
