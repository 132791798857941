import {
  AppstoreOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  SearchOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Version } from "../../models/version";

const versions: Version[] = [
  {
    version: "1.4.1",
    timestamp: 1695153626305,
    showAlert: false,
    changes: [
      {
        title: "Actualización de dependencias",
        avatar: <CloudDownloadOutlined />,
        description:
          "Se actualizan dependencias necesarias para el funcionamiento del sistema.",
      },
    ],
  },
  {
    version: "1.4.0",
    timestamp: 1677072310,
    showAlert: false,
    changes: [
      {
        title: "Notificaciones",
        avatar: <NotificationOutlined />,
        description:
          "Se agregan avisos de acciones pendientes en los sistemas.",
      },
      {
        title: "Actualización de dependencias",
        avatar: <CloudDownloadOutlined />,
        description:
          "Se actualizan dependencias necesarias para el funcionamiento del sistema.",
      },
    ],
  },
  {
    version: "1.3.0",
    timestamp: 1677072310,
    showAlert: false,
    changes: [
      {
        title: "Actualización de dependencias",
        avatar: <CloudDownloadOutlined />,
        description:
          "Se actualizan dependencias necesarias para el funcionamiento del sistema.",
      },
    ],
  },
  {
    version: "1.2.0",
    timestamp: 1667416985,
    showAlert: true,
    affectedRoles: ["listUsers", "listGroups"],
    changes: [
      {
        title: "Filtro por rol y grupo",
        avatar: <SearchOutlined />,
        description:
          "Se agrega la posibilidad de filtrar el listado de usuarios por un rol o un grupo.",
      },
    ],
  },
  {
    version: "1.1.2",
    timestamp: 1666617798,
    showAlert: false,
    changes: [
      {
        title: "Corrección de errores",
        avatar: <SettingOutlined />,
        description:
          "Se quita la posibilidad de asignar un rol que está incluido en un grupo que el usuario ya tiene asignado.",
      },
    ],
  },
  {
    version: "1.1.1",
    timestamp: 1664185249,
    showAlert: true,
    affectedRoles: ["listGroups", "editGroups"],
    changes: [
      {
        title: "Gestión de grupos",
        avatar: <UserSwitchOutlined />,
        description:
          "Se agrega la posibilidad de ver y editar los grupos de cada usuario.",
      },
    ],
  },
  {
    version: "1.1.0",
    timestamp: 1663604737,
    showAlert: true,
    affectedRoles: ["listUsers", "listRoles", "editRoles"],
    changes: [
      {
        title: "Lista de usuarios",
        avatar: <UsergroupAddOutlined />,
        description:
          "Se agrega la posibilidad de ver la lista de todos los usuarios.",
      },
      {
        title: "Gestión de roles",
        avatar: <UserSwitchOutlined />,
        description:
          "Se agrega la posibilidad de ver y editar los roles de cada usuario.",
      },
    ],
  },
  {
    version: "1.0.1",
    timestamp: 1652041356,
    showAlert: false,
    changes: [
      {
        title: "Roles en el listado de aplicaciones",
        avatar: <InfoCircleOutlined />,
        description:
          "Se agrega la posiblidad de ver los roles de cada aplicación.",
      },
    ],
  },
  {
    version: "1.0.0",
    timestamp: 1661859991,
    showAlert: false,
    changes: [
      {
        title: "Listado de aplicaciones",
        avatar: <AppstoreOutlined />,
        description: "Se muestran las aplicaciones disponibles.",
      },
      {
        title: "Edición de datos",
        avatar: <UserOutlined />,
        description:
          "Se muestran los datos del usuario y la posibilidad de editarlos.",
      },
    ],
  },
].slice(0, 25);

export default versions;
