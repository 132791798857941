import { Badge, Row, Spin, Tooltip, Typography } from "antd";
import { Avatar, Card, Col, Empty, Input } from "antd";
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  getUserApplications,
  getUserApplicationsNotifications,
} from "../../services/applications";
import { setTitle } from "../../redux/store/ducks/page";
import { useDispatch } from "react-redux";
import { Application } from "../../models/application";
import { User } from "../../models/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/ducks";

import { COLORS } from "./appsData";
import { InfoCircleOutlined } from "@ant-design/icons";
import AppDetailsDrawer from "./DetailsDrawer";

type ApplicationWithNotifications = Application & { notifications: number };

const { Title } = Typography;

const isValidApp = (app: Application) => {
  return app.clientName && app.description && app.baseUrl !== "";
};

const getBackgroundColor = (clientId: string) =>
  clientId in COLORS
    ? COLORS[clientId as keyof typeof COLORS]["background"]
    : "";

const getForegroundColor = (clientId: string) =>
  clientId in COLORS ? COLORS[clientId as keyof typeof COLORS]["text"] : "";

const getAvatarText = (clientName: string) =>
  `${clientName.substring(0, 1)}${clientName
    .substring(1, clientName.length)
    .replaceAll(/[aeiou]/gi, "")
    .substring(0, 1)}`.toUpperCase();

const AppCards: FC = memo(() => {
  const dispatch = useDispatch();
  const user: User = useSelector((state: RootState) => state.user);

  const [term, setTerm] = useState<string>("");
  const [apps, setApps] = useState<ApplicationWithNotifications[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 993);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 993)
  );

  useEffect(() => {
    dispatch(setTitle("Aplicaciones"));
    setLoading(true);

    Promise.all([
      getUserApplications(user.id),
      getUserApplicationsNotifications(user.id),
    ]).then(([apps, notifications]) => {
      apps = apps.filter(
        (app: Application) =>
          user.enabledClients.includes(app.clientId) && isValidApp(app)
      );
      const results = apps.map((app) => {
        const notificationsTotal = notifications[app.clientId] || 0;
        return {
          ...app,
          notifications: notificationsTotal,
        } as ApplicationWithNotifications;
      });
      setApps(results);
      setLoading(false);
    });
  }, [dispatch, user]);

  const applications = useMemo(
    () =>
      !term
        ? apps
        : apps.filter(
            (a) =>
              a.description
                .toLowerCase()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  term
                    .toLowerCase()
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) ||
              a.clientName
                .toLowerCase()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  term
                    .toLowerCase()
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
          ),
    [term, apps]
  );

  const [selected, setSelected] = useState<Application>();
  const showDrawer = (client: Application) => {
    setSelected(client);
  };
  const hideDrawer = useCallback(() => {
    setSelected(undefined);
  }, []);

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <div className="layout-content">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col span={24}>
          <Card style={{ marginBottom: 20 }} bodyStyle={{ padding: 10 }}>
            <Input
              allowClear
              bordered={false}
              placeholder="Buscar..."
              onChange={(event) => setTerm(event.target.value)}
            />
          </Card>
        </Col>

        {applications && applications.length ? (
          applications.map((c) => {
            const RibbonComponent = c.notifications
              ? Badge.Ribbon
              : (props: any) => <>{props.children}</>;
            return (
              <Col
                key={c.clientId}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                className="mb-24"
              >
                <RibbonComponent
                  text={
                    c.notifications
                      ? c.notifications > 99
                        ? `99+`
                        : c.notifications
                      : undefined
                  }
                  color={c.notifications ? "red" : undefined}
                >
                  <a href={c.effectiveUrl}>
                    <Card
                      hoverable
                      bordered={false}
                      style={{
                        height: "100%",
                        cursor: "pointer",
                        border: "1px solid #f0f0f0",
                        padding: !mobileSize ? "0px" : "8px",
                      }}
                      bodyStyle={{
                        padding: 15,
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Row
                        gutter={[18, 18]}
                        justify="space-between"
                        style={{ height: "100%", display: "flex" }}
                      >
                        <Col
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            justify="space-between"
                            style={{ height: "100%" }}
                          >
                            <Col
                              span={21}
                              style={{
                                height: 45,
                                display: "flex",
                                paddingBottom: 10,
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                gap={20}
                                style={{
                                  marginRight: 5,
                                  backgroundColor: getBackgroundColor(
                                    c.clientId
                                  ),
                                }}
                              >
                                <span
                                  style={{
                                    color: getForegroundColor(c.clientId),
                                  }}
                                >
                                  {getAvatarText(c.clientName)}
                                </span>
                              </Avatar>
                              <Title
                                level={5}
                                style={{
                                  padding: 0,
                                  fontSize: 18,
                                  marginTop: 0,
                                  marginLeft: 4,
                                  marginBottom: 0,
                                  color: "#3b3939",
                                }}
                              >
                                {c.clientName}
                              </Title>
                            </Col>
                            <Col
                              xs={3}
                              style={{
                                padding: "0px",
                                margin: "0px",
                                textAlign: "right",
                              }}
                            >
                              {!mobileSize ? (
                                <Tooltip title="Ver más">
                                  <InfoCircleOutlined
                                    style={{
                                      fontSize: "19px",
                                      color: "#808080",
                                      marginRight: c.notifications ? 17 : 0,
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      showDrawer(c);
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <InfoCircleOutlined
                                  style={{
                                    color: "#808080",
                                    fontSize: "20px",
                                    marginRight: c.notifications ? 17 : 0,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    showDrawer(c);
                                  }}
                                />
                              )}
                              <AppDetailsDrawer
                                client={c}
                                roles={c.roleMappings}
                                hideDrawer={hideDrawer}
                                visible={selected === c}
                              />
                            </Col>
                            <Col span={24}>{c.description}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </a>
                </RibbonComponent>
              </Col>
            );
          })
        ) : (
          <div
            style={{
              height: 300,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty description="No se encontraron aplicaciones" />
          </div>
        )}
      </Row>
    </div>
  );
});

export default AppCards;
