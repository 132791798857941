import { FC } from "react";
import { Layout, Row, Col } from "antd";
import { DefaultProps } from "../../models/react";
import dayjs from "dayjs";

const { Footer: AntFooter } = Layout;

const Footer: FC<DefaultProps> = () => {
  return (
    <AntFooter style={{ background: "#fafafa", marginTop: 10 }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            {`© ${dayjs().year()}`}
            <a href="/" className="font-weight-bold">
              Gral Saneamiento
            </a>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;
