import { useEffect } from "react";
import { useDispatch } from "react-redux";
//import { setTitle } from "redux/store/ducks/page";
import "./style.css";

const Page403 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(setTitle("Error"));
  }, [dispatch]);

  return (
    <div id="notfound" style={{ height: "80vh" }}>
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>No encontramos lo que buscabas</h2>
        <p></p>
      </div>
    </div>
  );
};

export default Page403;
