import { FC, useEffect, useReducer, useState } from "react";
import {
  Drawer,
  Empty,
  List,
  notification,
  Spin,
  Switch,
  Typography,
} from "antd";

import { User } from "../../../models/user";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/ducks";
import { Group } from "../../../models/group";
import { getUserGroups } from "../../../services/groups";
import { addUserGroup, deleteUserGroup } from "../../../services/groups";

interface GroupState extends Group {
  checked: boolean;
  loading: boolean;
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "CLEAR":
      return {
        ...state,
        groups: action.payload.groups.map((group: Group) => ({
          ...group,
          checked: false,
          loading: false,
        })),
      };
    case "SET_CHECKED":
      return {
        ...state,
        groups: state.groups.map((group: GroupState) => {
          if (group.id === action.payload.groupId)
            group.checked = action.payload.checked;
          return group;
        }),
      };
    case "SET_LOADING":
      return {
        ...state,
        groups: state.groups.map((group: GroupState) => {
          if (group.id === action.payload.groupId)
            group.loading = action.payload.loading;
          return group;
        }),
      };
    default:
      return state;
  }
};

const { Title } = Typography;

interface UserGroupsDrawerProps {
  selectedUser: User;
  allGroups: Group[];
  visible: boolean;
  hideDrawer: () => void;
}

export const UserGroupsDrawer: FC<UserGroupsDrawerProps> = ({
  selectedUser,
  allGroups,
  visible,
  hideDrawer,
}) => {
  const user: User = useSelector((state: RootState) => state.user);

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 500);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 500)
  );

  const [loading, setLoading] = useState<boolean>();

  const initialState = {
    groups: allGroups.map((group: Group) => ({
      ...group,
      checked: false,
      loading: false,
    })),
  };

  const updateInitialState = (allGroups: Group[], userGroups: Group[]) => {
    dispatch({ type: "CLEAR", payload: { groups: allGroups } });
    userGroups.forEach((group: Group) => {
      dispatch({
        type: "SET_CHECKED",
        payload: {
          groupId: group.id,
          checked: true,
        },
      });
    });
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      setLoading(true);
      getUserGroups(selectedUser.id)
        .then((groups: Group[]) => {
          updateInitialState(allGroups, groups);
        })
        .catch(() => {
          notification.error({
            message: "Error",
            description:
              "Se ha producido un error al obtener los grupos del usuario.",
          });
        })
        .finally(() => setLoading(false));
    }
  }, [selectedUser, allGroups]);

  const handleOnChange = (checked: boolean, group: Group) => {
    if (user.roles.includes("editGroups")) {
      dispatch({
        type: "SET_LOADING",
        payload: { groupId: group.id, loading: true },
      });

      const action = checked ? addUserGroup : deleteUserGroup;
      action(selectedUser, group)
        .then(() => {
          dispatch({
            type: "SET_CHECKED",
            payload: {
              groupId: group.id,
              checked: checked,
            },
          });
          notification.success({
            message: `Se ha ${checked ? "agregado" : "eliminado"} el grupo "${
              group.name
            }" al usuario "${selectedUser.username}" correctamente.`,
          });
        })
        .catch(() => {
          dispatch({
            type: "SET_CHECKED",
            payload: {
              groupId: group.id,
              checked: !checked,
            },
          });
          notification.error({
            message: `Ocurrió un error al ${
              checked ? "agregar" : "eliminar"
            } el grupo "${group.name}" al usuario "${selectedUser.username}".`,
          });
        })
        .finally(() =>
          dispatch({
            type: "SET_LOADING",
            payload: {
              groupId: group.id,
              loading: false,
            },
          })
        );
    }
  };

  return (
    <Drawer
      mask={true}
      width={!mobileSize ? 450 : "100%"}
      open={visible}
      onClose={hideDrawer}
      destroyOnClose={true}
      className="settings-drawer"
      title="Grupos del usuario"
    >
      <Title level={4}>
        {Object.keys(selectedUser).length ? (
          selectedUser.label
        ) : (
          <span>&nbsp;</span>
        )}
      </Title>
      <Title level={5}>Grupos:</Title>
      {loading ? (
        <div
          style={{
            width: "100%",
            marginTop: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {state.groups.length > 0 ? (
            <List
              dataSource={state.groups}
              renderItem={(group: GroupState) => (
                <List.Item
                  extra={
                    <Switch
                      checked={group.checked}
                      disabled={!user.roles.includes("editGroups")}
                      onChange={(checked) => handleOnChange(checked, group)}
                      loading={group.loading}
                      className="secondary-color-switch"
                    />
                  }
                >
                  {group.name}
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="No se encontraron grupos."
              style={{ marginTop: "100px" }}
            ></Empty>
          )}
        </>
      )}
    </Drawer>
  );
};

export default UserGroupsDrawer;
