import { AnyAction } from "redux";
import type { User } from "../../../models/user";

export const USERS_TYPES = {
  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAILURE: "USERS_FAILURE",
};

const types = USERS_TYPES;

export type UsersState = User[];

const initialState: UsersState = [];

const reducer = (state: UsersState = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.USERS_SUCCESS:
      return action.data;

    default:
      return state;
  }
};

export default reducer;
