import { useEffect, FC } from "react";
import { Row, Col, Button } from "antd";
import { NavLink } from "react-router-dom";
import { User } from "../../models/user";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { DefaultProps } from "../../models/react";

interface HeaderProps extends DefaultProps {
  user: User;
  name: string;
  subName: string;
  onPress: () => void;
  handleSidenavType: (value: string) => void;
  handleFixedNavbar: (value: boolean) => void;
  handleSidenavColor: (value: string) => void;
}

const Header: FC<HeaderProps> = function ({ user, onPress }) {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <Row gutter={[24, 0]}>
      <Col
        span={24}
        md={24}
        className="header-control"
        style={{ justifyContent: "space-between" }}
      >
        <NavLink to="/mis-datos">
          <div className="ant-page-header-heading" style={{ display: "flex" }}>
            <UserOutlined style={{ marginRight: "5px", color: "#575757" }} />
            <div
              className="ant-page-header-heading-sub-title"
              style={{ fontWeight: 600, fontSize: 13, color: "black" }}
            >
              {user.firstName} {user.lastName}
            </div>
          </div>
        </NavLink>
        <Button
          type="link"
          className="sidebar-toggler"
          onClick={() => onPress()}
        >
          <MenuOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
