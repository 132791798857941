export const uris = {
  UPDATE_USER_URI: String(process.env.REACT_APP_UPDATE_USER_URI),
  APPLICATIONS_URI: String(process.env.REACT_APP_USERS_API_URI) + "clients",
  USER_APPLICATIONS_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "clients/:userId",
  USER_APPLICATIONS_NOTIFICATIONS_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "notifications/:userId",
  GROUPS_URI: String(process.env.REACT_APP_USERS_API_URI) + "groups",
  USER_GROUPS_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "groups/:userId",
  ADD_USER_GROUP_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "groups/add",
  DELETE_USER_GROUP_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "groups/delete",
  USERS_URI: String(process.env.REACT_APP_USERS_API_URI) + "users",
  ADD_CLIENT_USER_ROLE_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "roles/add",
  DELETE_CLIENT_USER_ROLE_URI:
    String(process.env.REACT_APP_USERS_API_URI) + "roles/delete",
};
