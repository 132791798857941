import "./style.css";

const Page403 = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>403</h1>
        </div>
        <h2>Lo sentimos</h2>
        <p>
          No tienes permisos para acceder a este sitio. Si crees que es un error
          comunicate con el área de sistemas.
        </p>
      </div>
    </div>
  );
};

export default Page403;
