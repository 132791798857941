import axios from "axios";
import { orderBy } from "lodash";
import { uris } from "../config/vars";

import type { Application } from "../models/application";

export const getAllApplications = () => {
  const url = uris.APPLICATIONS_URI;
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  return axios
    .get<Application[]>(url, config)
    .then((e) => orderBy(e.data, "clientName"));
};

export const getUserApplications = (userId: string) => {
  const url = uris.USER_APPLICATIONS_URI.replace(":userId", userId);
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  return axios
    .get<Application[]>(url, config)
    .then((e) => orderBy(e.data, "clientName"));
};

export const getUserApplicationsNotifications = (userId: string) => {
  const url = uris.USER_APPLICATIONS_NOTIFICATIONS_URI.replace(
    ":userId",
    userId
  );
  const config = {
    headers: {
      Accept: "application/json",
    },
  };
  return axios.get<Record<string, number>>(url, config).then((e) => e.data);
};
