import { Button, Form, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { FC, useState } from "react";
import { User } from "../../../models/user";
import store from "../../../redux/store";
import { loginUser } from "../../../redux/store/ducks/user";
import { updateUser } from "../../../services/users";
import { DrawerForm, ProFormText } from "@ant-design/pro-form";

const capitalize = (s: string) =>
  s
    .trim()
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

export const EditUserDrawer: FC<{
  user: User;
  visible: boolean;
  hideDrawer: () => void;
}> = ({ user, visible, hideDrawer }) => {
  const [form] = Form.useForm();
  const formIsValid = (form: FormInstance) =>
    !form.getFieldsError().some(({ errors }) => errors.length > 0);

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: "Error",
      description: "Ocurrió un error al actualizar los datos.",
    });
    return false;
  };
  const onFinish = async (form: any) => {
    form = {
      ...form,
      firstName: capitalize(form.firstName),
      lastName: capitalize(form.lastName),
      username: String(form.username).toLowerCase(),
      email: String(form.email).toLowerCase().trim(),
    };
    const values = { ...user, ...form };
    return updateUser(values)
      .then(() => {
        store.dispatch(loginUser(values));
        hideDrawer();
        notification.success({
          message: "Datos actualizados",
          description: "Los datos han sido actualizados correctamente.",
        });
        return true;
      })
      .catch((error) => {
        if (error.response.status === 409) {
          notification.error({
            message: "Error",
            description:
              error.response.data.errorMessage === "usernameExistsMessage"
                ? "El nombre de usuario ya está en uso."
                : "El email ya está en uso.",
          });
          return false;
        } else onFinishFailed(error);
      });
  };

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 500);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 500)
  );

  const [disabledSave, setDisabledSave] = useState(false);

  return (
    <DrawerForm
      drawerProps={{
        mask: true,
        onClose: () => {
          hideDrawer();
          form.resetFields();
        },
        destroyOnClose: true,
        footer: (
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                hideDrawer();
                form.resetFields();
              }}
              style={{ marginRight: "5px" }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="edit-user-form"
              style={{ marginLeft: "5px" }}
              disabled={disabledSave}
            >
              Guardar
            </Button>
          </div>
        ),
      }}
      width={!mobileSize ? 375 : "100%"}
      open={visible}
      title="Editar datos"
      className="settings-drawer"
      id="edit-user-form"
      form={form}
      layout="vertical"
      initialValues={{ ...user }}
      autoComplete="off"
      style={{ textAlign: "center" }}
      onFinishFailed={onFinishFailed}
      onFinish={(values) => onFinish(values)}
      onFieldsChange={() => setDisabledSave(!formIsValid(form))}
    >
      <ProFormText
        label="Nombre"
        name="firstName"
        rules={[{ required: true, message: "Por favor, ingrese su nombre" }]}
      />

      <ProFormText
        label="Apellido"
        name="lastName"
        rules={[{ required: true, message: "Por favor, ingrese su apellido" }]}
      />

      <ProFormText
        label="Usuario"
        name="username"
        rules={[
          {
            required: true,
            message: "Por favor, ingrese un usuario sin espacios",
            pattern: /^\S*$/,
          },
        ]}
      />

      <ProFormText
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Por favor, ingrese un email válido",
            type: "email",
          },
        ]}
      />
    </DrawerForm>
  );
};

export default EditUserDrawer;
