import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

// import logger from "redux-logger";
import rootReducer from "./ducks";

const initialState = {};
const enhancers = [];
const middleware = [thunkMiddleware];

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const store = createStore(
  rootReducer(),
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);

export default store;
