import { FC, useEffect, useReducer, useState } from "react";
import {
  Badge,
  Collapse,
  Drawer,
  Empty,
  List,
  notification,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";

import { User } from "../../../models/user";
import { Application } from "../../../models/application";
import { getUserApplications } from "../../../services/applications";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/ducks";
import { Role } from "../../../models/role";
import {
  addClientUserRole,
  deleteClientUserRole,
} from "../../../services/roles";
import { Group } from "../../../models/group";
import { getUserGroups } from "../../../services/groups";

const isValidApp = (app: Application) => {
  return app.clientName && app.description && app.baseUrl !== "";
};

interface RoleState extends Role {
  checked: boolean;
  loading: boolean;
}

interface ApplicationState extends Application {
  roleMappings: RoleState[];
  checkedRolesCount: number;
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "CLEAR":
      return {
        ...state,
        apps: action.payload.apps.map((app: Application) => ({
          ...app,
          roleMappings: app.roleMappings.map((role: Role) => ({
            ...role,
            checked: false,
            loading: false,
          })),
          checkedRolesCount: 0,
        })),
      };
    case "UPDATE_CHECKED_COUNT":
      return {
        ...state,
        apps: state.apps.map((app: ApplicationState) =>
          app.clientId === action.payload.appClientId
            ? { ...app, checkedRolesCount: action.payload.checkedRolesCount }
            : app
        ),
      };
    case "SET_CHECKED":
      return {
        ...state,
        apps: state.apps.map((app: ApplicationState) => {
          if (app.clientId === action.payload.appClientId)
            app.roleMappings = app.roleMappings.map((role: RoleState) =>
              role.id === action.payload.roleId
                ? { ...role, checked: action.payload.checked }
                : role
            );
          return app;
        }),
      };
    case "SET_LOADING":
      return {
        ...state,
        apps: state.apps.map((app: ApplicationState) => {
          if (app.clientId === action.payload.appClientId)
            app.roleMappings = app.roleMappings.map((role: RoleState) =>
              role.id === action.payload.roleId
                ? { ...role, loading: action.payload.loading }
                : role
            );
          return app;
        }),
      };
    default:
      return state;
  }
};

const { Title } = Typography;

interface UserRolesDrawerProps {
  selectedUser: User;
  allApps: Application[];
  visible: boolean;
  hideDrawer: () => void;
}

export const UserRolesDrawer: FC<UserRolesDrawerProps> = ({
  selectedUser,
  allApps,
  visible,
  hideDrawer,
}) => {
  const user: User = useSelector((state: RootState) => state.user);

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 500);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 500)
  );

  const [loading, setLoading] = useState<boolean>();

  const initialState = {
    apps: allApps.map((app: Application) => ({
      ...app,
      roleMappings: app.roleMappings.map((role: Role) => ({
        ...role,
        checked: false,
        loading: false,
      })),
      checkedRolesCount: 0,
    })),
  };

  const updateInitialState = (
    allApps: Application[],
    userApps: Application[]
  ) => {
    dispatch({ type: "CLEAR", payload: { apps: allApps } });
    userApps.forEach((app: Application) => {
      dispatch({
        type: "UPDATE_CHECKED_COUNT",
        payload: {
          appClientId: app.clientId,
          checkedRolesCount: app.roleMappings.length,
        },
      });
      app.roleMappings.forEach((role: Role) =>
        dispatch({
          type: "SET_CHECKED",
          payload: {
            appClientId: app.clientId,
            roleId: role.id,
            checked: true,
          },
        })
      );
    });
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [userGroups, setUserGroups] = useState<Group[]>([]);

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      setLoading(true);
      getUserApplications(selectedUser.id)
        .then((userApps) => {
          userApps = userApps.filter((app: Application) => isValidApp(app));
          updateInitialState(allApps, userApps);
          getUserGroups(selectedUser.id).then((groups) =>
            setUserGroups(groups)
          );
        })
        .catch(() => {
          notification.error({
            message: "Error",
            description:
              "Se ha producido un error al obtener los roles del usuario.",
          });
        })
        .finally(() => setLoading(false));
    }
  }, [selectedUser, allApps]);

  const roleIsInGroup = (roleId: string) =>
    userGroups.some((group: Group) =>
      group.roleMappings.some((role: Role) => role.id === roleId)
    );

  const handleOnChange = (checked: boolean, app: Application, role: Role) => {
    if (user.roles.includes("editRoles")) {
      dispatch({
        type: "SET_LOADING",
        payload: { appClientId: app.clientId, roleId: role.id, loading: true },
      });

      const action = checked ? addClientUserRole : deleteClientUserRole;
      action(selectedUser, role)
        .then(() => {
          const rolesCount = checked
            ? state.apps.find(
                (a: ApplicationState) => a.clientId === app.clientId
              ).checkedRolesCount + 1
            : state.apps.find(
                (a: ApplicationState) => a.clientId === app.clientId
              ).checkedRolesCount - 1;
          dispatch({
            type: "UPDATE_CHECKED_COUNT",
            payload: {
              appClientId: app.clientId,
              checkedRolesCount: rolesCount,
            },
          });
          dispatch({
            type: "SET_CHECKED",
            payload: {
              appClientId: app.clientId,
              roleId: role.id,
              checked: checked,
            },
          });
          notification.success({
            message: `Se ha ${checked ? "agregado" : "eliminado"} el rol "${
              role.description
            }" al usuario "${selectedUser.username}" correctamente.`,
          });
        })
        .catch(() => {
          dispatch({
            type: "SET_CHECKED",
            payload: {
              appClientId: app.clientId,
              roleId: role.id,
              checked: !checked,
            },
          });
          notification.error({
            message: `Ocurrió un error al ${
              checked ? "agregar" : "eliminar"
            } el rol "${role.description}" al usuario "${
              selectedUser.username
            }".`,
          });
        })
        .finally(() =>
          dispatch({
            type: "SET_LOADING",
            payload: {
              appClientId: app.clientId,
              roleId: role.id,
              loading: false,
            },
          })
        );
    }
  };

  return (
    <Drawer
      mask={true}
      width={!mobileSize ? 450 : "100%"}
      open={visible}
      onClose={hideDrawer}
      destroyOnClose={true}
      className="settings-drawer"
      title="Roles del usuario"
    >
      <Title level={4}>
        {Object.keys(selectedUser).length ? (
          selectedUser.label
        ) : (
          <span>&nbsp;</span>
        )}
      </Title>
      <Title level={5}>Roles:</Title>
      {loading ? (
        <div
          style={{
            width: "100%",
            marginTop: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {state.apps.length > 0 ? (
            <Collapse>
              {state.apps.map((app: ApplicationState) => (
                <Collapse.Panel
                  header={app.clientName || app.clientId}
                  extra={
                    <Badge
                      style={{
                        color: "white",
                        background: "#595959",
                        width: "50px",
                      }}
                      count={`${app.checkedRolesCount} / ${app.roleMappings.length}`}
                    />
                  }
                  key={app.clientId}
                  style={{ userSelect: "none" }}
                >
                  <List
                    dataSource={app.roleMappings.sort((a, b) =>
                      a.description?.localeCompare(b.description)
                    )}
                    renderItem={(role: RoleState) => (
                      <List.Item
                        extra={
                          !roleIsInGroup(role.id) ? (
                            <Switch
                              checked={role.checked}
                              disabled={!user.roles.includes("editRoles")}
                              onChange={(checked) =>
                                handleOnChange(checked, app, role)
                              }
                              loading={role.loading}
                            />
                          ) : (
                            <Tooltip
                              title="No se puede modificar el rol porque pertenece a un grupo
                              que el usuario tiene asignado."
                              placement="topRight"
                            >
                              <Switch
                                checked={role.checked}
                                disabled={true}
                                onChange={(checked) =>
                                  handleOnChange(checked, app, role)
                                }
                                loading={role.loading}
                              />
                            </Tooltip>
                          )
                        }
                      >
                        {role.description || role.name}
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          ) : (
            <Empty
              description="No se encontraron aplicaciones."
              style={{ marginTop: "100px" }}
            ></Empty>
          )}
        </>
      )}
    </Drawer>
  );
};

export default UserRolesDrawer;
