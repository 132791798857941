import { combineReducers } from "redux";

import userReducer, { UserState } from "./user";
import pageReducer, { PageState } from "./page";
import usersReducer, { UsersState } from "./users";
import keycloakReducer, { KeycloakState } from "./keycloak";

export interface RootState {
  user: UserState;
  page: PageState;
  users: UsersState;
  keycloak: KeycloakState;
}

const createRootReducer = () =>
  combineReducers({
    user: userReducer,
    page: pageReducer,
    users: usersReducer,
    keycloak: keycloakReducer,
  });

export default createRootReducer;
