import { Avatar, List, Modal, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { User } from "../models/user";
import { RootState } from "../redux/store/ducks";
import versions from "../pages/Versioning/versions";
import { Version } from "../models/version";

const { Title } = Typography;

const VersionAlert: FC = () => {
  const user: User = useSelector((state: RootState) => state.user);
  const lastVersion: Version = versions[0];

  const [isModalOpen, setIsModalOpen] = useState<boolean>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    localStorage.setItem(`${user.id}-${lastVersion.version}`, "1");
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (user && user.id) {
      const lastSeenVersion = localStorage.getItem(
        `${user.id}-${lastVersion.version}`
      );
      const isAffected =
        !lastVersion.affectedRoles ||
        user.roles.some((r: string) => lastVersion.affectedRoles?.includes(r));
      if (!lastSeenVersion && isAffected) showModal();
    }
  }, [user, lastVersion]);

  if (!lastVersion.showAlert) return null;

  return (
    <Modal
      footer={null}
      centered={true}
      open={isModalOpen}
      onCancel={hideModal}
      title={`La versión ${lastVersion.version} incorpora:`}
    >
      <List
        dataSource={lastVersion.changes}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <Row style={{ width: "100%" }}>
              <Avatar shape="square">{item.avatar}</Avatar>
              <span style={{ marginLeft: "15px", paddingTop: "5px" }}>
                <Title level={5}>{item.title}</Title>
              </span>
              <p style={{ marginTop: "10px" }}>{item.description}</p>
            </Row>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default VersionAlert;
