import { Menu } from "antd";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/gral/logo.png";

import packageJson from "../../../package.json";
import {
  AppstoreOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Keycloak from "keycloak-js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/ducks";
import { DefaultProps } from "../../models/react";
import { User } from "../../models/user";

interface SidenavProps extends DefaultProps {
  color: string;
}

const Sidenav: FC<SidenavProps> = function ({ color }) {
  const user: User = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const keycloak: Keycloak = useSelector(
    (state: RootState) => state.keycloak.kc
  ) as any;

  const menuItems = [
    {
      key: "1",
      label: (
        <NavLink to="/aplicaciones">
          <span
            className="icon"
            style={{
              background: page === "aplicaciones" ? color : "",
            }}
          >
            {<AppstoreOutlined />}
          </span>
          <span className="label">Aplicaciones</span>
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink to="/mis-datos">
          <span
            className="icon"
            style={{
              background: page === "mis-datos" ? color : "",
            }}
          >
            {<UserOutlined />}
          </span>
          <span className="label">Mis datos</span>
        </NavLink>
      ),
    },
  ];

  if (user.roles.includes("listUsers"))
    menuItems.push({
      key: "3",
      label: (
        <NavLink to="/gestion-de-usuarios">
          <span
            className="icon"
            style={{
              background: page === "gestion-de-usuarios" ? color : "",
            }}
          >
            {<UsergroupAddOutlined />}
          </span>
          <span className="label">Gestión de usuarios</span>
        </NavLink>
      ),
    });

  return (
    <>
      <div className="brand" style={{ display: "flex", alignItems: "center" }}>
        <img src={logo} alt="" style={{ marginRight: 5 }} />
        <span style={{ display: "flex", alignItems: "baseline" }}>
          <span>Usuarios</span>
          <small onClick={() => {}} style={{ fontSize: 10, marginLeft: 5 }}>
            <NavLink to="/actualizaciones">v{packageJson.version}</NavLink>
          </small>
        </span>
      </div>
      <hr />
      <Menu theme="light" mode="inline" items={menuItems} />
      <Menu
        theme="light"
        mode="inline"
        style={{
          margin: "5px 5px",
          padding: "5px",
          position: "sticky",
          bottom: 0,
          top: "100%",
        }}
        items={[
          {
            key: "1",
            icon: <LogoutOutlined />,
            onClick: () => keycloak.logout(),
            label: "Cerrar sesión",
          },
        ]}
      />
    </>
  );
};

export default Sidenav;
