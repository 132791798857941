import { FC, useState } from "react";
import { Drawer, List, Typography } from "antd";

import { Application } from "../../../models/application";
import { Role } from "../../../models/role";
import { DefaultProps } from "../../../models/react";

const { Title } = Typography;

interface AppDetailsDrawerProps extends DefaultProps {
  client: Application;
  roles: Role[];
  visible: boolean;
  hideDrawer: (event: any) => void;
}

export const AppDetailsDrawer: FC<AppDetailsDrawerProps> = ({
  client,
  roles,
  visible,
  hideDrawer,
}) => {
  const [mobileSize, setMobileSize] = useState(window.innerWidth < 500);
  window.addEventListener("resize", () =>
    setMobileSize(window.innerWidth < 500)
  );

  return (
    <Drawer
      mask={true}
      width={!mobileSize ? 375 : "100%"}
      open={visible}
      onClose={hideDrawer}
      className="settings-drawer"
      title="Detalles de la aplicación"
    >
      <Title level={4}>{client.clientName}</Title>
      <span>{client.description}</span>
      <br />
      <br />
      <Title level={5}>Roles:</Title>
      <List
        dataSource={roles.sort((a, b) =>
          a.description?.localeCompare(b.description)
        )}
        renderItem={(item) => (
          <List.Item>{item.description || item.name}</List.Item>
        )}
      />
    </Drawer>
  );
};

export default AppDetailsDrawer;
