import { Routes, Route, Navigate } from "react-router-dom";

import Page403 from "./pages/Error/403";
import Page404 from "./pages/Error/404";

import Main from "./components/layout/Main";
import Applications from "./pages/Applications";
import MyAccount from "./pages/MyAccount";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import { ConfigProvider, Spin } from "antd";

import "dayjs/locale/es";
import locale from "antd/lib/locale/es_ES";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store/ducks";

import logo from "./assets/images/gral/full_logo.png";
import background from "./assets/images/gral/home_bg.png";
import UserManagement from "./pages/UserManagement/UserList";
import VersioningList from "./pages/Versioning";

function App() {
  const user = useSelector((state: RootState) => state.user);
  const authError = useSelector((state: RootState) => state.keycloak.authError);
  const roleError = useSelector((state: RootState) => state.keycloak.roleError);

  if (authError || roleError) return <Page403 />;

  if (!user.id)
    return (
      <div
        style={{
          width: "100vw",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${background})`,
        }}
      >
        <img width="300" src={logo} alt="logo de gral" />
        <div
          style={{
            marginTop: -20,
            padding: 98,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" tip="Realizando autenticación de usuario..." />
        </div>
      </div>
    );

  return (
    <ConfigProvider
      locale={locale}
      theme={{ token: { colorPrimary: "#488cc5" } }}
    >
      <div className="App">
        <Main>
          <Routes>
            <Route path="/aplicaciones" element={<Applications />} />
            <Route path="/mis-datos" element={<MyAccount user={user} />} />

            {user.roles.includes("listUsers") ? (
              <Route path="/gestion-de-usuarios" element={<UserManagement />} />
            ) : null}

            <Route path="/actualizaciones" element={<VersioningList />} />
            <Route path="*" element={<Page404 />} />

            <Route path="/" element={<Navigate to="/aplicaciones" />} />
          </Routes>
        </Main>
      </div>
    </ConfigProvider>
  );
}

export default App;
